import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { LinkContainer } from "react-router-bootstrap";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Dropdown from "react-bootstrap/Dropdown";

function List(props) {

  return (
    <div className="relative">
      <Navbar bg={props.bg} variant={props.variant} expand={props.expand}>
        <Container>
          <Navbar.Toggle
            aria-controls="navbar-nav"
            className="border-0 block text-sm font-medium text-gray-700"
          />
          <Navbar.Collapse id="listbox-label" className="justify-content-end">
            <Nav>
              <div className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-4 pr-8 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 font-medium sm:text-sm">
              <NavDropdown
                id="dropdown"
                data-toggle="dropdown"
                title={<span>Company Selector </span>}
                alignRight={true}
              >
                <LinkContainer to="/dashboard">
                  <NavDropdown.Item active={false}>Ameritas</NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to="/settings/general">
                  <NavDropdown.Item active={false}>Upserve</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default List;
