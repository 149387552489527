import React from "react";
import "./../styles/global.scss";
import NavbarCustom from "./../components/NavbarCustom";
import IndexPage from "./index";
import FeaturesPage from "./features";
import PlansPage from "./plans";
import AboutPage from "./about";
import FAQPage from "./faq";
import WhyPage from "./why";
import PrivacySecurityPage from "./privacy-security";
import BlogPage from "./blog";
import ContactPage from "./contact";
import DashboardPage from "./dashboard";
import OldDashboardPage from "./dashboard-old-version";
import SettingsPage from "./settings";
import PurchasePage from "./purchase";
import AuthPage from "./auth";
import PostCyberblitzPage from "./post/cyberblitz";
import { Switch, Route, Router } from "./../util/router.js";
import FirebaseActionPage from "./firebase-action.js";
import NotFoundPage from "./not-found.js";
import Footer from "./../components/Footer";
import "./../util/analytics.js";
import { AuthProvider } from "./../util/auth.js";
import "./_app.scss";
import ListBox from "./list";

function App(props) {
  return (
    <AuthProvider>
      <Router>
        <>
          <div className="App__navbar-wrapper">
            <NavbarCustom
              header_position="relative"
              header_bg="bg-white"
              logo="https://consumeraffinity.com/wp-content/uploads/2021/03/logo.png"
              home_alt="Cyberblitz.it Home"
              home_link="/"
            />
          </div>

          <Switch>
            <Route exact path="/" component={IndexPage} />

            <Route exact path="/features" component={FeaturesPage} />

            <Route exact path="/plans" component={PlansPage} />

            <Route exact path="/about" component={AboutPage} />

            <Route exact path="/faqs" component={FAQPage} />

            <Route exact path="/blog" component={BlogPage} />

            <Route exact path="/contact" component={ContactPage} />

            <Route exact path="/why" component={WhyPage} />

            <Route exact path="/list" component={ListBox} />

            <Route
              exact
              path="/privacy-security"
              component={PrivacySecurityPage}
            />

            <Route exact path="/dashboard" component={DashboardPage} />

            <Route
              exact
              path="/dashboard-old-version"
              component={OldDashboardPage}
            />

            <Route exact path="/settings/:section" component={SettingsPage} />

            <Route exact path="/purchase/:plan" component={PurchasePage} />

            <Route exact path="/auth/:type" component={AuthPage} />

            <Route
              exact
              path="/post/cyberblitz"
              component={PostCyberblitzPage}
            />

            <Route
              exact
              path="/firebase-action"
              component={FirebaseActionPage}
            />

            <Route component={NotFoundPage} />
          </Switch>

          <Footer
            bg="white"
            textColor="dark"
            size="md"
            bgImage=""
            bgImageOpacity={1}
            description="Cybersecurity for everyone."
            copyright="© 2021 Consumer Affinity, Inc."
            logo="https://consumeraffinity.com/wp-content/uploads/2021/01/logo.png"
          />
        </>
      </Router>
    </AuthProvider>
  );
}

export default App;
