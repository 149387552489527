// Map our custom plan IDs ("basic", "premium", etc) to Stripe price IDs
const stripePriceIds = {
  starter: process.env.REACT_APP_STRIPE_PRICE_STARTER,
  pro: process.env.REACT_APP_STRIPE_PRICE_PRO,
  business: process.env.REACT_APP_STRIPE_PRICE_BUSINESS
};

// Get Stripe priceId
export function getStripePriceId(planId) {
  return stripePriceIds[planId];
}

// Get friendly plan ID ("basic", "premium", etc) by Stripe plan ID
// Used in auth.js to include planId in the user object
export function getFriendlyPlanId(stripePriceId) {
  return Object.keys(stripePriceIds).find(
    (key) => stripePriceIds[key] === stripePriceId
  );
}

// Map plan IDs to limits - added by Steve 3.16.2021
const limit = {
  starter: 10,
  pro: 25,
  business: 10000
};

// Get friendly plan ID - added by Steve 3.16.2021
export function getLimit(planId) {
  return limit[planId];
}

// Start company count at 0 - added by Steve 3.16.2021
const co_count = {
  starter: 0,
  pro: 0,
  business: 0
};

// Get company count - added by Steve 3.16.2021
export function getCo_Count(planId) {
  return co_count[planId];
}
