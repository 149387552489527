import React from "react";
import DashboardSection from "./../components/DashboardSection";
import { requireAuth } from "./../util/auth.js";

function OldDashboardPage(props) {
  return (
    <DashboardSection
      bg="white"
      textColor="dark"
      size="lg"
      bgImage=""
      bgImageOpacity={1}
      title="Account Dashboard"
      subtitle=""
    />
  );
}

export default requireAuth(OldDashboardPage);
