import React from "react";

function HeroSection11(props) {
  return (
    <div className="bg-gray-900">
      <div className="py-12 px-4 sm:px-6 lg:px-8 lg:pt-20 lg:pb-80 md:pb-20 sm:pb-10">
        <div className="text-center">
          <h2 className="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">
            {props.title}
          </h2>
          <p className="mt-2 text-3xl font-extrabold text-white sm:text-4xl lg:text-5xl">
            {props.subtitle1}
          </p>
          <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
            {props.subtitle2}
          </p>
        </div>
      </div>
    </div>
  );
}
export default HeroSection11;
