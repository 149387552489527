import React from "react";
import { useAuth } from "./../util/auth.js";
import { Transition } from "@headlessui/react";
import { useState } from "react";
function NavbarCustom(props) {
  const auth = useAuth();
  const [isMenu1open, setMenu1open] = useState(false);
  const [isMenu2open, setMenu2open] = useState(false);
  const [isHamburgerOpen, setHamburgerOpen] = useState(false);
  return (
    <div className="header" id="myHeader">
      <div className={[props.header_position, props.header_bg].join(" ")}>
        <div className="max-w-full mx-auto px-4 sm:px-6">
          <div className="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <a href={props.home_link}>
                <span className="sr-only">
                  Home of the affordable Attack Surface Analysis
                </span>
                <img
                  className="h-20 w-auto sm:h-20"
                  src={props.logo}
                  alt={props.home_alt}
                />
              </a>
            </div>
            <div className="mt-1 -my-16 md:hidden">
              <button
                onClick={() => setHamburgerOpen(!isHamburgerOpen)}
                type="button"
                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-500"
                aria-expanded="false"
              >
                <span className="sr-only">Open menu</span>
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>

            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0 h-auto grid grid-rows-3 grid-flow-col gap-3">
              <div>
                {auth.user && (
                  <a
                    href="/dashboard"
                    className="hover:no-underline text-base font-medium text-gray-500 hover:text-gray-900"
                  >
                    Dashboard
                  </a>
                )}
              </div>
            </div>

            <nav className="hidden md:flex space-x-10">
              <div className="relative">
                <button
                  type="button"
                  onClick={() => {
                    setMenu1open(!isMenu1open);
                    setMenu2open(false);
                  }}
                  className="text-gray-500 group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  aria-expanded="false"
                >
                  <span>Our Solution</span>
                  <svg
                    className="text-gray-400 ml-2 h-5 w-5 group-hover:text-gray-500 transition ease-in-out duration-150"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                <Transition
                  show={isMenu1open}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <div className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0 lg:max-w-3xl">
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                      <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                        <a
                          href="/features"
                          className="hover:no-underline -m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                        >
                          <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gray-200 text-white sm:h-12 sm:w-12">
                            <svg
                              className="h-6 w-6 text-red-600"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                strokeLinecap="round"
                                strikeLinejoin="round"
                                strokeWidth="2"
                                d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z"
                              />
                            </svg>
                          </div>
                          <div className="ml-4">
                            <p className="text-base font-medium text-gray-900">
                              Domains
                            </p>
                            <p className="mt-1 text-sm text-gray-500">
                              Domains are enumerated using company name or
                              registrant email address.
                            </p>
                          </div>
                        </a>
                        <a
                          href="/features"
                          className="hover:no-underline -m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                        >
                          <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gray-200 text-white sm:h-12 sm:w-12">
                            <svg
                              className="h-6 w-6 text-red-600"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                strokeLinecap="round"
                                strikeLinejoin="round"
                                strokeWidth="2"
                                d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4"
                              />
                            </svg>
                          </div>
                          <div className="ml-4">
                            <p className="text-base font-medium text-gray-900">
                              Identification
                            </p>
                            <p className="mt-1 text-sm text-gray-500">
                              Identification of devices and databases using open
                              ports and banners.
                            </p>
                          </div>
                        </a>
                        <a
                          href="/features"
                          className="hover:no-underline -m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                        >
                          <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gray-200 text-white sm:h-12 sm:w-12">
                            <svg
                              className="h-6 w-6 text-red-600"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                strokeLinecap="round"
                                strikeLinejoin="round"
                                strokeWidth="2"
                                d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
                              />
                            </svg>
                          </div>
                          <div className="ml-4">
                            <p className="text-base font-medium text-gray-900">
                              Domain Name Services
                            </p>
                            <p className="mt-1 text-sm text-gray-500">
                              Domains are enumerated using company name or
                              registrant email address.
                            </p>
                          </div>
                        </a>
                        <a
                          href="/features"
                          className="hover:no-underline -m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                        >
                          <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gray-200 text-white sm:h-12 sm:w-12">
                            <svg
                              className="flex-shrink-0 h-6 w-6 text-red-600"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                              />
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                              />
                            </svg>
                          </div>
                          <div className="ml-4">
                            <p className="text-base font-medium text-gray-900">
                              Configurations
                            </p>
                            <p className="mt-1 text-sm text-gray-500">
                              Information and recommendations about TLS/SSL
                              Certificates and other security issues.
                            </p>
                          </div>
                        </a>
                        <a
                          href="/features"
                          className="hover:no-underline -m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                        >
                          <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gray-200 text-white sm:h-12 sm:w-12">
                            <svg
                              className="flex-shrink-0 h-6 w-6 text-red-600"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01"
                              />
                            </svg>
                          </div>
                          <div className="ml-4">
                            <p className="text-base font-medium text-gray-900">
                              Targets
                            </p>
                            <p className="mt-1 text-sm text-gray-500">
                              ARIN, domains, and DNS records help identify
                              potential target assets.
                            </p>
                          </div>
                        </a>
                        <a
                          href="/features"
                          className="hover:no-underline -m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                        >
                          <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gray-200 text-white sm:h-12 sm:w-12">
                            <svg
                              className="h-6 w-6 text-red-600"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                strokeLinecap="round"
                                strikeLinejoin="round"
                                strokeWidth="2"
                                d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                              />
                            </svg>
                          </div>
                          <div className="ml-4">
                            <p className="text-base font-medium text-gray-900">
                              Reports
                            </p>
                            <p className="mt-1 text-sm text-gray-500">
                              Get detailed reports that will help you make more
                              informed decisions
                            </p>
                          </div>
                        </a>
                      </div>
                      <div className="flex flex-wrap overflow-hidden px-5 py-4 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                        <div className="w-1/2 overflow-hidden">
                          <a
                            href="/how-it-works"
                            className="hover:no-underline p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100"
                          >
                            <svg
                              className="flex-shrink-0 h-6 w-6 text-gray-400"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                              />
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                            <span className="ml-3">Watch Explainer</span>
                          </a>
                        </div>
                        <div>
                          <a
                            href="tel:713-689-9742"
                            className="hover:no-underline p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100"
                          >
                            <svg
                              className="flex-shrink-0 h-6 w-6 text-gray-400"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                              />
                            </svg>
                            <span className="ml-3">Sales: (713) 689-9742</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Transition>
              </div>
            </nav>

            <nav className="hidden md:flex space-x-10">
              <a
                href="plans"
                className="hover:no-underline text-base font-medium text-gray-500 hover:text-gray-900"
              >
                Plans
              </a>
              <a
                href="/why"
                className="hover:no-underline text-base font-medium text-gray-500 hover:text-gray-900"
              >
                Why
              </a>
              <div className="relative">
                <button
                  onClick={() => {
                    setMenu2open(!isMenu2open);
                    setMenu1open(false);
                  }}
                  type="button"
                  className="hover:no-underline text-gray-500 group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  aria-expanded="false"
                >
                  <span>More</span>
                  <svg
                    className="text-gray-400 ml-2 h-5 w-5 group-hover:text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                <Transition
                  show={isMenu2open}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <div className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0">
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                      <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                        <a
                          href="/about"
                          className="hover:no-underline -m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                        >
                          <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gray-200 text-white sm:h-12 sm:w-12">
                            <svg
                              className="h-6 w-6 text-red-600"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                strokeLinecap="round"
                                strikeLinejoin="round"
                                strokeWidth="2"
                                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          </div>
                          <div className="ml-4">
                            <p className="text-base font-medium text-gray-900">
                              About
                            </p>
                            <p className="mt-1 text-sm text-gray-500">
                              The experienced information security team members
                              behind Cyberblitz.it.
                            </p>
                          </div>
                        </a>
                        <a
                          href="/faqs"
                          className="hover:no-underline -m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                        >
                          <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gray-200 text-white sm:h-12 sm:w-12">
                            <svg
                              className="h-6 w-6 text-red-600"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                strokeLinecap="round"
                                strikeLinejoin="round"
                                strokeWidth="2"
                                d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          </div>
                          <div className="ml-4">
                            <p className="text-base font-medium text-gray-900">
                              FAQs
                            </p>
                            <p className="mt-1 text-sm text-gray-500">
                              Answers to frequently asked questions.
                            </p>
                          </div>
                        </a>
                        <a
                          href="/privacy-security"
                          className="hover:no-underline -m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                        >
                          <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gray-200 text-white sm:h-12 sm:w-12">
                            <svg
                              className="h-6 w-6 text-red-600"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                strokeLinecap="round"
                                strikeLinejoin="round"
                                strokeWidth="2"
                                d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                              />
                            </svg>
                          </div>
                          <div className="ml-4">
                            <p className="text-base font-medium text-gray-900">
                              Security
                            </p>
                            <p className="mt-1 text-sm text-gray-500">
                              Understand how we take your security and privacy
                              seriously.
                            </p>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </Transition>
              </div>
            </nav>

            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0 h-auto grid grid-rows-3 grid-flow-col gap-3">
              <div>
                {!auth.user && (
                  <a
                    href="/auth/signin"
                    className="hover:no-underline whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"
                  >
                    Sign in
                  </a>
                )}
              </div>
              <div>
                {!auth.user && (
                  <a
                    href="/auth/signup"
                    className="hover:no-underline ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-600 hover:bg-red-700"
                  >
                    Sign up
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
        <Transition
          show={isHamburgerOpen}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="navbar -mt-4">
            <nav>
              <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
                <div className="rounded-lg shadow-lg ring-1 tw-ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                  <div className="-mt-14">
                    <div className="flex items-center justify-between">
                      <div>
                        <br></br>
                      </div>
                      <div className="mt-3 mr-3">
                        <button
                          className="hover:no-underline bg-white rounded-md p-2 inline-flex
                              items-center justify-center text-gray-400
                              hover:text-gray-500 hover:bg-gray-100 focus:outline-none
                              focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                          onClick={() => setHamburgerOpen(!isHamburgerOpen)}
                        >
                          <svg
                            className="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div>
                      <div className="ml-8 mt-6 mb-2">
                        <nav className="grid gap-y-8">
                          <a
                            href="/features"
                            className="hover:no-underline -m-3 p-1 flex items-center rounded-md hover:bg-gray-50"
                          >
                            <svg
                              className="flex-shrink-0 h-6 w-6 text-red-600"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                              />
                            </svg>
                            <span className="ml-3 text-base font-medium text-gray-900">
                              Domains
                            </span>
                          </a>
                          <a
                            href="/features"
                            className="hover:no-underline -m-3 p-1 flex items-center rounded-md hover:bg-gray-50"
                          >
                            <svg
                              className="flex-shrink-0 h-6 w-6 text-red-600"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
                              />
                            </svg>
                            <span className="ml-3 text-base font-medium text-gray-900">
                              Domain Name Services
                            </span>
                          </a>
                          <a
                            href="/features"
                            className="hover:no-underline -m-3 p-1 flex items-center rounded-md hover:bg-gray-50"
                          >
                            <svg
                              className="flex-shrink-0 h-6 w-6 text-red-600"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01"
                              />
                            </svg>
                            <span className="ml-3 text-base font-medium text-gray-900">
                              Targets
                            </span>
                          </a>
                          <a
                            href="/features"
                            className="hover:no-underline -m-3 p-1 flex items-center rounded-md hover:bg-gray-50"
                          >
                            <svg
                              className="flex-shrink-0 h-6 w-6 text-red-600"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4"
                              />
                            </svg>
                            <span className="ml-3 text-base font-medium text-gray-900">
                              Identification
                            </span>
                          </a>
                          <a
                            href="/features"
                            className="hover:no-underline -m-3 p-1 flex items-center rounded-md hover:bg-gray-50"
                          >
                            <svg
                              className="flex-shrink-0 h-6 w-6 text-red-600"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                              />
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                              />
                            </svg>
                            <span className="ml-3 text-base font-medium text-gray-900">
                              Configurations
                            </span>
                          </a>
                          <a
                            href="/features"
                            className="hover:no-underline -m-3 p-1 flex items-center rounded-md hover:bg-gray-50"
                          >
                            <svg
                              className="flex-shrink-0 h-6 w-6 text-red-600"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                              />
                            </svg>
                            <span className="ml-3 text-base font-medium text-gray-900">
                              Recommendations
                            </span>
                          </a>
                        </nav>
                      </div>
                    </div>
                    <div className="py-4 px-4 space-y-6">
                      <div className="grid grid-cols-2 gap-y-1">
                        <a
                          href="/plans"
                          className="hover:no-underline text-base font-medium text-gray-900 hover:text-gray-700"
                        >
                          Plans
                        </a>
                        <a
                          href="/about"
                          className="hover:no-underline text-base font-medium text-gray-900 hover:text-gray-700"
                        >
                          About
                        </a>
                        <a
                          href="/why"
                          className="hover:no-underline text-base font-medium text-gray-900 hover:text-gray-700"
                        >
                          Why
                        </a>
                        <a
                          href="/how-it-works"
                          className="hover:no-underline text-base font-medium text-gray-900 hover:text-gray-700"
                        >
                          Explainer Video
                        </a>
                        <a
                          href="/faqs"
                          className="hover:no-underline text-base font-medium text-gray-900 hover:text-gray-700"
                        >
                          FAQs
                        </a>
                        <a
                          href="tel:713-689-9742"
                          className="hover:no-underline text-base font-medium text-gray-900 hover:text-gray-700"
                        >
                          Call Sales!
                        </a>
                        <a
                          href="/privacy-security"
                          className="hover:no-underline text-base font-medium text-gray-900 hover:text-gray-700"
                        >
                          Security & Privacy
                        </a>
                      </div>
                      <div>
                        {!auth.user && (
                          <a
                            href="/auth/signup"
                            className="hover:no-underline w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-600 hover:bg-red-700"
                          >
                            Sign up
                          </a>
                        )}
                        {!auth.user && (
                          <p className="mt-6 text-center text-base font-medium text-gray-500">
                            Existing customer?
                            <a
                              href="/auth/signin"
                              className="hover:no-underline text-blue-700 hover:text-blue-500"
                            >
                              {" "}
                              Sign in
                            </a>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </Transition>
      </div>
    </div>
  );
}
export default NavbarCustom;
