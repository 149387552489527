import React from "react";

function CtaSection2() {
  return (
    <div className="bg-white">
      <div className="max-w-4xl mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          <span className="block">Ready to give it a go?</span>
          <span className="block bg-gradient-to-r from-red-600 to-pink-500 bg-clip-text text-transparent">
            Start a 14-day free trial.
          </span>
        </h2>
        <div className="mt-6 grid grid-cols-3 gap-4">
          <a
            href="/auth/signup"
            className="hover:no-underline w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-600 hover:bg-red-700 md:py-4 md:text-lg md:px-10"
          >
            Sign up
          </a>
          <a
            href="/plans"
            className="hover:no-underline w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-600 hover:bg-red-700 md:py-4 md:text-lg md:px-10"
          >
            See Pricing
          </a>
        </div>
      </div>
    </div>
  );
}

export default CtaSection2;
