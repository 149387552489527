import React from "react";
import HeroSection11 from "./../components/HeroSection11";
import PricingSection from "./../components/PricingSection";
import FaqSection from "./../components/BillingFaqSection";

function PlansPage(props) {
  return (
    <>
      <HeroSection11
        title="Pricing"
        subtitle1="The right price for you, whoever you are"
        subtitle2="No contract, cancel anytime you want. Enterprise features at micro-saas prices."
      />
      <PricingSection
        bg="white"
        textColor="dark"
        size="lg"
        bgImage=""
        bgImageOpacity={1}
        title=""
        subtitle=""
      />
      <FaqSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Plan & Billing FAQs"
        subtitle=""
      />
    </>
  );
}

export default PlansPage;
