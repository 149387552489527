import React from "react";

function ClientsSection() {
  return (
    <div className="relative py-24 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width="404"
            height="384"
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="384"
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width="404"
            height="384"
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="384"
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width="404"
            height="384"
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="384"
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              Get a Handle on
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Complexities
            </span>
          </h1>
          <p className="mt-6 text-xl text-gray-500 leading-8 tracking-tight text-center">
            Technology has created both opportunity and criminal digital markets
          </p>
        </div>
        <div className="mt-6 text-gray-500 mx-auto text-center">
          <ul>
            <li>Data providers (stolen credentials, card numbers)</li>
            <li>Hackers for hire (pay-to-compromise)</li>
            <li>Opportunists (scams, extortion, crimeware)</li>
          </ul>
          <h2>
            <span className="mt-2 mb-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Introducing Cyberblitz.it
            </span>
          </h2>
          <blockquote>
            <p>
              "We won't solve all of your infosec problems but we try our best
              to help solve some of cybersecurity's needle in a haystack
              problem."
            </p>
          </blockquote>
          <div className="text-center">
            <p>~Steve R. Smith, CEO and Founder</p>
          </div>
        </div>
        <div className="flex flex-wrap overflow-hidden">
          <div className="w-full overflow-hidden">
            <center>
              <figure>
                <img
                  className="mb-3 mt-3 w-50 rounded-lg"
                  src="https://consumeraffinity.com/wp-content/uploads/2021/01/networking.svg"
                  alt=""
                  width="1310"
                  height="873"
                ></img>
                <figcaption>
                  There are 230,000,000+ servers on the Internet. How many are
                  yours?
                </figcaption>
              </figure>
            </center>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientsSection;
