import React, { useState } from "react";
import FormAlert from "./FormAlert";
import AuthForm from "./AuthForm";
import AuthSocial from "./AuthSocial";
import AuthFooter from "./AuthFooter";
import { useRouter } from "./../util/router.js";

function Auth(props) {
  const router = useRouter();
  const [formAlert, setFormAlert] = useState(null);

  const handleAuth = (user) => {
    router.push(props.afterAuthPath);
  };

  const handleFormAlert = (data) => {
    setFormAlert(data);
  };

  return (
    <>
      {formAlert && (
        <FormAlert type={formAlert.type} message={formAlert.message} />
      )}

      {["signin"].includes(props.type) && (
        <p className="text-center text-sm text-gray-600 max-w">
          Or
          <a
            href="/plans"
            className="ml-1 hover:no-underline font-medium text-indigo-600 hover:text-indigo-500"
          >
            start your 14-day free trial
          </a>
        </p>
      )}

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-5 px-4 shadow sm:rounded-lg sm:px-10">
          <AuthForm
            type={props.type}
            typeValues={props.typeValues}
            onAuth={handleAuth}
            onFormAlert={handleFormAlert}
          />

          {["signup", "signin"].includes(props.type) && (
            <>
              {props.providers && props.providers.length && (
                <>
                  <div class="mt-6 mb-6">
                    <div class="relative">
                      <div class="absolute inset-0 flex items-center">
                        <div class="w-full border-t d-block border-gray-300"></div>
                      </div>
                      <div class="relative flex justify-center text-sm">
                        <span class="px-2 bg-white text-gray-500">
                          Or continue with
                        </span>
                      </div>
                    </div>
                  </div>

                  <AuthSocial
                    type={props.type}
                    buttonText={props.typeValues.buttonText}
                    providers={props.providers}
                    showLastUsed={true}
                    onAuth={handleAuth}
                    onError={(message) => {
                      handleFormAlert({
                        type: "error",
                        message: message
                      });
                    }}
                  />
                </>
              )}

              <AuthFooter
                classname="hover:no-underline font-medium text-indigo-600 hover:text-indigo-500"
                type={props.type}
                typeValues={props.typeValues}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Auth;
