import React from "react";
import HeroSection10 from "./../components/HeroSection10";
import WhySection from "./../components/WhySection";

function WhyPage(props) {
  return (
    <>
      <HeroSection10 />

      <WhySection />
    </>
  );
}

export default WhyPage;
