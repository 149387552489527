import React from "react";
import HeroSection11 from "./../components/HeroSection11";
import FaqSection from "./../components/FaqSection";
import CtaSection from "./../components/CtaSection";

function FAQPage(props) {
  return (
    <>
      <HeroSection11
        title="Questions?"
        subtitle1="We Have Answers"
        subtitle2="The right experience to help you improve your Cybersecurity Program."
      />
      <FaqSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Feel free to contact us for help: support@consumeraffinity.com"
        subtitle=""
      />
      <CtaSection
        bg="primary"
        textColor="light"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="Ready to get started?"
        subtitle=""
        buttonText="Get Started"
        buttonColor="light"
        buttonPath="/plans"
      />
    </>
  );
}

export default FAQPage;
