import React from "react";
import HeroSection9 from "./../components/HeroSection9";
import ASAFeaturesSection from "./../components/AsaFeaturesSection";
import CtaSection2 from "./../components/CtaSection2";

function FeaturesPage(props) {
  return (
    <>
      <HeroSection9
        bg="white"
        textColor="dark"
        size="lg"
        bgImage=""
        bgImageOpacity={1}
        title="Quickly discover what people can find out about your company by scouring public repositories"
        subtitle="By the time you finish analyzing domain names, netblocks, ports, and banners, the data is already stale! Use Cyberblitz.it's continuous attack surface analysis do the work for you."
      />
      <ASAFeaturesSection
        bg="white"
        textColor="dark"
        size="lg"
        bgImage=""
        bgImageOpacity={1}
        title="Quickly discover what people can find out about your company by scouring public repositories"
        subtitle="By the time you finish analyzing domain names, netblocks, ports, and banners, the data is already stale! Use Cyberblitz.it's continuous attack surface analysis do the work for you."
      />
      <CtaSection2 />
    </>
  );
}

export default FeaturesPage;
