import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import { useAuth } from "./../util/auth.js";
import "./PricingSection.scss";

function PricingSection(props) {
  const auth = useAuth();

  const items = [
    {
      id: "starter",
      name: "Starter",
      price: "99",
      perks: [
        "Up to 10 different companies",
        "Company profile",
        "Domain discovery",
        "DNS discovery",
        "Target enumeration",
        "Device identification",
        "Web tech identification",
        "Dangerous device analysis",
        "SSL/TLS configuration analysis",
        "Unencrypted protocol analysis",
        "Recommendations"
      ]
    },
    {
      id: "pro",
      name: "Professional",
      price: "299",
      perks: [
        "Up to 25 different companies",
        "Company profile",
        "Domain discovery",
        "DNS discovery",
        "Target enumeration",
        "Device identification",
        "Web tech identification",
        "Dangerous device analysis",
        "SSL/TLS configuration analysis",
        "Unencrypted protocol analysis",
        "Recommendations"
      ]
    },
    {
      id: "business",
      name: "Business",
      price: "499",
      perks: [
        "Unlimited companies",
        "Company profile",
        "Domain discovery",
        "DNS discovery",
        "Target enumeration",
        "Device identification",
        "Web tech identification",
        "Dangerous device analyis",
        "SSL/TLS configuration analysis",
        "Unencrypted protocol analysis",
        "Recommendations"
      ]
    }
  ];

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={1}
          spaced={true}
          className="text-center"
        />
        <Row className="justify-content-center">
          {items.map((item, index) => (
            <Col
              md={12}
              lg={4}
              className="d-flex align-items-stretch"
              key={index}
            >
              <Card className="w-100 lg:-mt-80 sm:mt-0 md:mt-0 border-0 rounded-lg shadow-sm">
                <Card.Body className="d-flex shadow-lg flex-column p-4">
                  <h5 className="font-weight-bold mb-4 text-left">
                    {item.name}
                  </h5>
                  <h1 className="font-weight-bold mb-3 text-left">
                    ${item.price}
                    <small className="PricingSection__period text-xl">
                      /month
                    </small>
                  </h1>
                  <hr></hr>
                  <h3 className="ml-4 text-xs font-medium text-gray-900 tracking-wide uppercase">
                    What's included
                  </h3>
                  {item.description && (
                    <Card.Text className="mb-4">{item.description}</Card.Text>
                  )}

                  {item.perks && (
                    <div className="flex flex-wrap overflow-hidden self-center">
                      <div className="w-full overflow-hidden">
                        <Card.Text as="span" className="mt-2 mb-3">
                          <ul className="list-unstyled">
                            {item.perks.map((perk, index) => (
                              <li className="py-1" key={index}>
                                <i className="far fa-check-square text-success mr-3" />
                                {perk}
                              </li>
                            ))}
                          </ul>
                        </Card.Text>
                      </div>
                    </div>
                  )}

                  <LinkContainer
                    to={
                      auth.user
                        ? `/purchase/${item.id}`
                        : `/auth/signup?next=/purchase/${item.id}`
                    }
                  >
                    <Button
                      variant="red-600"
                      size="md"
                      block={true}
                      className="hover:no-underline w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-600 hover:bg-red-700 md:py-4 md:text-lg md:px-10"
                    >
                      Start 14 Day Free Trial
                    </Button>
                  </LinkContainer>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
}

export default PricingSection;
