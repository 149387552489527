import React, { useState } from "react";
import { useAuth } from "./../util/auth.js";
import Form from "react-bootstrap/Form";
import FormField from "./FormField";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "./../util/router.js";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Nav from "react-bootstrap/Nav";
import { requireAuth } from "./../util/auth.js";
import { useForm } from "react-hook-form";

function DashSelector(props) {
  const auth = useAuth();

  const [pending, setPending] = useState(false);

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true);

    return auth
      .updateProfile(data)
      .then(() => {
        // Set success status
        props.onStatus({
          type: "success",
          message: "Your profile has been updated"
        });
      })
      .catch((error) => {
        if (error.code === "auth/requires-recent-login") {
          props.onStatus({
            type: "requires-recent-login",
            // Resubmit after reauth flow
            callback: () => onSubmit(data)
          });
        } else {
          // Set error status
          props.onStatus({
            type: "error",
            message: error.message
          });
        }
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };

  return (
    <div class="flex flex-wrap">
      <div class="w-1/2 overflow-hidden">
        <div class="flex items-center">
          <span class="inline-block h-14 w-14 rounded-full overflow-hidden bg-gray-100">
            <svg
              className="h-full w-full text-gray-300"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
          </span>
          <div>
            <div class="ml-8 mt-4 flex items-center">
              {!auth.user.name && (
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group controlId="formName">
                    <FormField
                      name="name"
                      type="text"
                      label="Please enter your name"
                      defaultValue={auth.user.name}
                      placeholder="Full name"
                      error={errors.name}
                      size="lg"
                      inputRef={register({
                        required: "Please enter your name"
                      })}
                    />
                  </Form.Group>

                  <Button
                    className="hover:no-underline whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-600 hover:bg-red-700"
                    type="submit"
                    size="lg"
                    disabled={pending}
                  >
                    <span>Save</span>

                    {pending && (
                      <Spinner
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden={true}
                        className="ml-2 align-baseline"
                      >
                        <span className="sr-only">Sending...</span>
                      </Spinner>
                    )}
                  </Button>
                </Form>
              )}

              {auth.user.name && (
                <h1 class="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                  Hello, {auth.user.name}
                </h1>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="w-1/2 mt-3">
        {auth.user.stripeSubscriptionId && (
          <Navbar>
            <Container>
              <Navbar.Toggle
                aria-controls="navbar-nav"
                className="border-0 block text-sm font-medium text-gray-700"
              />
              <Navbar.Collapse
                id="listbox-label"
                className="justify-content-right"
              >
                <Nav>
                  <div className="mt-2 w-full bg-white border border-gray-300 rounded-md shadow-sm pl-5 pr-5 text-right cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 font-medium sm:text-sm">
                    <NavDropdown
                      id="dropdown"
                      data-toggle="dropdown"
                      title={<span>Company Selector </span>}
                      alignRight={true}
                    >
                      <LinkContainer to="/dashboard/">
                        <NavDropdown.Item active={false}>
                          Ameritas
                        </NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/upserve/dashboard/">
                        <NavDropdown.Item active={false}>
                          Upserve
                        </NavDropdown.Item>
                      </LinkContainer>
                    </NavDropdown>
                  </div>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        )}
      </div>

      {!auth.user.stripeSubscriptionId && (
        <div className="mt-3 mb-3">
          You are not subscribed to a plan. Please select a plan below to use
          Cyberblitz.it.
        </div>
      )}

      {auth.user.stripeSubscriptionId && (
        <>
          <div className="mt-2 mb-2">
            You are subscribed to the <strong>{auth.user.planId} plan</strong> (
            {auth.user.stripeSubscriptionStatus}).
            <p>
              Subscription: {auth.user.co_count} of {auth.user.limit} companies
              added -{" "}
              <Link
                className="hover:no-underline font-medium text-indigo-600 hover:text-indigo-500"
                to="/settings/billing"
              >
                <strong>upgrade</strong>
              </Link>{" "}
              to add more.
            </p>
          </div>
        </>
      )}
    </div>
  );
}

export default requireAuth(DashSelector);
