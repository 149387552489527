import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import FormField from "./FormField";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Link, useRouter } from "./../util/router.js";
import { useAuth } from "./../util/auth.js";
import { useForm } from "react-hook-form";

function SettingsGeneral(props) {
  const auth = useAuth();
  const router = useRouter();
  const [pending, setPending] = useState(false);

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true);

    return auth
      .updateProfile(data)
      .then(() => {
        // Set success status
        props.onStatus({
          type: "success",
          message: "Your profile has been updated"
        });
      })
      .catch((error) => {
        if (error.code === "auth/requires-recent-login") {
          props.onStatus({
            type: "requires-recent-login",
            // Resubmit after reauth flow
            callback: () => onSubmit(data)
          });
        } else {
          // Set error status
          props.onStatus({
            type: "error",
            message: error.message
          });
        }
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={1}
          spaced={true}
          className="text-center"
        />

        {router.query.paid && auth.user.planIsActive && (
          <Alert
            variant="success"
            className="text-center mx-auto mb-5"
            style={{ maxWidth: "400px" }}
          >
            You are now subscribed to the {auth.user.planId} plan
            <span className="ml-2" role="img" aria-label="party">
              🥳
            </span>
          </Alert>
        )}

        <Row>
          <Col lg={6} className="mt-4 mt-lg-0">
            <Card>
              <Card.Body>
                <h2 className="mb-3">General Settings</h2>
                <div>
                  <div className="mt-2 mb-2">
                    You are currently signed in as{" "}
                    <strong>{auth.user.email}</strong>.
                  </div>

                  {!auth.user.stripeSubscriptionId && (
                    <div className="mt-2 mb-2">
                      You are not subscribed to a plan. Please signup for a{" "}
                      <Link
                        className="hover:no-underline font-medium text-indigo-600 hover:text-indigo-500"
                        to="/plans"
                      >
                        <strong>plan</strong>
                      </Link>
                      .
                    </div>
                  )}

                  {auth.user.stripeSubscriptionId && (
                    <>
                      <div className="mt-2 mb-2">
                        You are subscribed to the{" "}
                        <strong>{auth.user.planId} plan</strong>.
                      </div>
                      <div className="mt-2 mb-2">
                        Your plan status is{" "}
                        <strong>{auth.user.stripeSubscriptionStatus}</strong>.
                      </div>
                    </>
                  )}

                  {auth.user.stripeSubscriptionId && (
                    <div className="mt-2 mb-2">
                      You can change your account info{` `}
                      {auth.user.stripeSubscriptionId && <>and plan{` `}</>}
                      in{` `}
                      <Link
                        className="hover:no-underline font-medium text-indigo-600 hover:text-indigo-500"
                        to="/settings/billing"
                      >
                        <strong>billing settings</strong>
                      </Link>
                      .
                    </div>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group controlId="formName">
                <FormField
                  name="name"
                  type="text"
                  label="Name"
                  defaultValue={auth.user.name}
                  placeholder="Name"
                  error={errors.name}
                  size="lg"
                  inputRef={register({
                    required: "Please enter your name"
                  })}
                />
              </Form.Group>
              <Form.Group controlId="formEmail">
                <FormField
                  name="email"
                  type="email"
                  label="Email Address"
                  defaultValue={auth.user.email}
                  placeholder="Email"
                  error={errors.email}
                  size="lg"
                  inputRef={register({
                    required: "Please enter your email"
                  })}
                />
              </Form.Group>
              <Button
                className="hover:no-underline whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-600 hover:bg-red-700"
                type="submit"
                size="lg"
                disabled={pending}
              >
                <span>Save</span>

                {pending && (
                  <Spinner
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden={true}
                    className="ml-2 align-baseline"
                  >
                    <span className="sr-only">Sending...</span>
                  </Spinner>
                )}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </Section>
  );
}

export default SettingsGeneral;
