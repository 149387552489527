import React from "react";
import HeroSection11 from "./../components/HeroSection11";
import SecuritySection from "./../components/SecuritySection";

function PrivacySecurityPage(props) {
  return (
    <>
      <HeroSection11
        title="Concerned?"
        subtitle1="You Shouldn't Be"
        subtitle2="We have developed a solution that works to protect your data by design."
      />
      <SecuritySection />
    </>
  );
}

export default PrivacySecurityPage;
