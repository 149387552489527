import React from "react";
import { useAuth } from "./../util/auth.js";
import { requireAuth } from "./../util/auth.js";
import SideBarNav from "./../components/SideBarNav";
import DashSelector from "./../components/DashSelector";
import PricingSection from "./../components/PricingSection";
import DashSection from "./../components/DashSection";
import DashSection2 from "./../components/DashSection2";
import DashSearchSection from "./../components/DashSearchSection";

function DashBoardPage(props) {
  const auth = useAuth();

  return (
    <>
      <div class="flex flex-wrap overflow-hidden">
        <div class="w-1/6 overflow-hidden">
          <SideBarNav />
        </div>
        <div class="w-5/6 overflow-hidden">
          <DashSelector />

          {!auth.user.stripeSubscriptionId && (
            <div className="relative mt-80">
              <PricingSection />
            </div>
          )}

          {auth.user.stripeSubscriptionId && <DashSection />}
          {auth.user.stripeSubscriptionId && <DashSection2 />}
          {auth.user.stripeSubscriptionId && <DashSearchSection />}
        </div>
      </div>
    </>
  );
}

export default requireAuth(DashBoardPage);
