import React from "react";
import { requireAuth } from "./../util/auth.js";
import DashSearch3 from "./DashSearch3";

function DashSearchSection(props) {
  return (
    <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
      <div class="flex flex-wrap overflow-hidden">
        <div className="w-full overflow-hidden">
          <h2 className="mt-3 text-lg font-medium leading-6 text-gray-900 sm:truncate">
            COMPANY SEARCH
          </h2>
        </div>
        <div className="w-full overflow-hidden">
          <DashSearch3 />
        </div>
      </div>
    </div>
  );
}

export default requireAuth(DashSearchSection);
