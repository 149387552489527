import React from "react";
import { requireAuth } from "./../util/auth.js";

function DashSection(props) {
  return (
    <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
      <div class="flex flex-wrap overflow-hidden">
        <div className="w-1/2 overflow-hidden">
          <h2 className="mt-3 text-lg font-medium leading-6 text-gray-900 sm:truncate">
            ACTIONABLE INTELLIGENCE
          </h2>
        </div>

        <ul className="grid grid-cols-3 md:grid-cols-3 gap-4 sm:gap-6 mt-3">
          <li className="relative col-span-1 flex shadow-sm rounded-md">
            <div className="flex-shrink-0 flex items-center justify-center w-16 bg-red-600 text-white text-sm font-medium rounded-l-md"></div>
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
              <div className="flex-1 px-4 py-2 text-sm truncate">
                <a
                  href="/"
                  className="hover:no-underline text-gray-900 font-medium hover:text-gray-600"
                >
                  Potential Issues
                  <p className="text-gray-500">12</p>
                </a>
              </div>
              <div className="flex-shrink-0 pr-2"></div>
            </div>
          </li>
          <li className="relative col-span-1 flex shadow-sm rounded-md">
            <div className="flex-shrink-0 flex items-center justify-center w-16 bg-pink-600 text-white text-sm font-medium rounded-l-md"></div>
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
              <div className="flex-1 px-4 py-2 text-sm truncate">
                <a
                  href="/"
                  className="hover:no-underline text-gray-900 font-medium hover:text-gray-600"
                >
                  Domains Expiring 30 days
                  <p className="text-gray-500">22</p>
                </a>
              </div>
              <div className="flex-shrink-0 pr-2"></div>
            </div>
          </li>
          <li className="relative col-span-1 flex shadow-sm rounded-md">
            <div className="flex-shrink-0 flex items-center justify-center w-16 bg-indigo-600  text-white text-sm font-medium rounded-l-md"></div>
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
              <div className="flex-1 px-4 py-2 text-sm truncate">
                <a
                  href="/"
                  className="hover:no-underline text-gray-900 font-medium hover:text-gray-600"
                >
                  Observations
                  <p className="text-gray-500">6</p>
                </a>
              </div>
              <div className="flex-shrink-0 pr-2"></div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default requireAuth(DashSection);
