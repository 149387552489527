import React, { useState } from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import "./FaqSection.scss";

function FaqSection(props) {
  // Object to store expanded state for all items
  const [expanded, setExpanded] = useState({});
  // Set an item's expanded state
  const setExpandedItem = (index, isExpanded) => {
    setExpanded({
      ...expanded,
      [index]: isExpanded
    });
  };

  const items = [
    {
      question: "How do I pay?",
      answer:
        "Since we area small micro SaaS Company, we don't have the resources for traditional contracting, invoices, and accounts receivable, instead users pay monthly with a credit card."
    },
    {
      question: "Can I cancel my subscription?",
      answer:
        "Yes, without notice and any time you want. Simply log into your account, go to Billing and cancel. Once the current billing cycle ends, you won't charged."
    },
    {
      question: "Can I upgrade/downgrade my subscription?",
      answer:
        "Absolutely. Many large organizations have own many companies, have several divisions, ownership interests, or partnerships that require them to assess aspects of their security controls. You can upgrade or downgrade to any level of Cyberblitz.it account."
    }
  ];

  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
        <div className="lg:grid lg:grid-cols-3 lg:gap-8">
          <div>
            <h2 className="text-3xl font-extrabold text-gray-900">
              Frequently asked questions
            </h2>
            <p className="mt-4 text-lg text-gray-500">
              Can’t find the answer you’re looking for? Reach out to our{" "}
              <a
                href="mailto:support@consumeraffinity.com"
                className="hover:no-underline font-medium text-red-600 hover:text-indigo-500"
              >
                customer support
              </a>{" "}
              team.
            </p>
          </div>
          <div className="mt-12 lg:mt-0 lg:col-span-2">
            <dl className="space-y-12">
              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Why do you charge by company instead of by asset?
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  We want to provide easy to understand, transparent, and fair
                  pricing.
                </dd>
              </div>

              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Do you provide an "enterprise" type of license for a specific
                  number of users?
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  Not at this time we don't.
                </dd>
              </div>

              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Do you charge per user?
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  Yes, our current pricing is per company, per user and self
                  serve.
                </dd>
              </div>

              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Do you offer a free trial?
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  Yes, it's currently 14 days free.
                </dd>
              </div>

              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  How do I pay?
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  At the moment, we only accept credit card payments.
                </dd>
              </div>

              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Can I cancel my subscription?
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  Yes, whenever you want. Once the current billing cycle ends,
                  you won't charged.
                </dd>
              </div>

              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Can I upgrade/downgrade my subscription?
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  You can upgrade or downgrade to any level of account depending
                  on your needs.
                </dd>
              </div>

              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Why do you never see elephants hiding in trees?
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  Because they&#039;re so good at it. Just seeing if you're
                  paying attention...
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FaqSection;
