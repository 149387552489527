import React from "react";
import HeroSection11 from "./../components/HeroSection11";
import StatsSection from "./../components/StatsSection";
import TeamBiosSection from "./../components/TeamBiosSection";
import CtaSection from "./../components/CtaSection";

function AboutPage(props) {
  return (
    <>
      <HeroSection11
        title="The Right Experience"
        subtitle1="Cyberblitz.it Mitigates Risk"
        subtitle2="Our team are experienced information security professionals."
      />
      <StatsSection
        bg="light"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
      />
      <TeamBiosSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Meet the Team"
        subtitle="We have decades of combined experience in information security. Our experience includes application security, penetration testing, vulnerability management, operating system security, network and enterprise security monitoring and more!"
      />
      <CtaSection
        bg="primary"
        textColor="light"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="Ready to get started?"
        subtitle=""
        buttonText="Get Started"
        buttonColor="light"
        buttonPath="/plans"
      />
    </>
  );
}

export default AboutPage;
