import React from "react";
import SettingsSection from "./../components/SettingsSection";
import { useRouter } from "./../util/router.js";
import { requireAuth } from "./../util/auth.js";
import SideBarNav from "./../components/SideBarNav";
import SettingsAvatar from "../components/SettingsAvatar";

function SettingsPage(props) {
  const router = useRouter();

  return (
    <div className="flex flex-wrap overflow-hidden">
      <div className="w-1/5 overflow-hidden">
        <>
          <SideBarNav />
        </>
      </div>

      <div className="w-3/5 overflow-hidden align-top">
        <SettingsSection
          bg="white"
          textColor="dark"
          size="md"
          bgImage=""
          bgImageOpacity={1}
          section={router.query.section}
          key={router.query.section}
        />

        <SettingsAvatar />
      </div>

      <div className="w-1/5 overflow-hidden"></div>
    </div>
  );
}

export default requireAuth(SettingsPage);
