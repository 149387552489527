import React from "react";
import { requireAuth } from "./../util/auth.js";

function DashSection2(props) {
  return (
    <div className="px-4 mt-4 sm:px-6 lg:px-8">
      <h2 className="mt-3 text-lg font-medium leading-6 text-gray-900 sm:truncate">
        ASSET SUMMARY
      </h2>
      <div className="flex flex-wrap overflow-hidden">
        <div className="w-1/4 overflow-hidden">
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="p-3">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                    />
                  </svg>
                </div>
                <div className="ml-3 w-0 flex-1">
                  <dl>
                    <dt className="text-sm font-medium text-gray-500 truncate">
                      Web Servers
                    </dt>
                    <dd>
                      <div className="text-lg font-medium text-gray-900">
                        59
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-5 py-3">
              <div className="text-sm">
                <a
                  href="/"
                  className="relative -mt-4 hover:no-underline font-medium text-indigo-600 hover:text-indigo-500"
                >
                  View all
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/4 overflow-hidden">
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="p-3">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4"
                    />
                  </svg>
                </div>
                <div className="ml-3 w-0 flex-1">
                  <dl>
                    <dt className="text-sm font-medium text-gray-500 truncate">
                      Database Servers
                    </dt>
                    <dd>
                      <div className="text-lg font-medium text-gray-900">0</div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-5 py-3">
              <div className="text-sm">
                <a
                  href="/"
                  className="relative -mt-4 hover:no-underline font-medium text-indigo-600 hover:text-indigo-500"
                >
                  View all
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/4 overflow-hidden">
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="p-3">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                    />
                  </svg>
                </div>
                <div className="ml-3 w-0 flex-1">
                  <dl>
                    <dt className="text-sm font-medium text-gray-500 truncate">
                      FTP Servers
                    </dt>
                    <dd>
                      <div className="text-lg font-medium text-gray-900">7</div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-5 py-3">
              <div className="text-sm">
                <a
                  href="/"
                  className="relative -mt-4 hover:no-underline font-medium text-indigo-600 hover:text-indigo-500"
                >
                  View all
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/4 overflow-hidden">
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="p-3">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    />
                  </svg>
                </div>
                <div className="ml-3 w-0 flex-1">
                  <dl>
                    <dt className="text-sm font-medium text-gray-500 truncate">
                      Telnet Servers
                    </dt>
                    <dd>
                      <div className="text-lg font-medium text-gray-900">2</div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-5 py-3">
              <div className="text-sm">
                <a
                  href="/"
                  className="hover:no-underline font-medium text-indigo-600 hover:text-indigo-500"
                >
                  View all
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default requireAuth(DashSection2);
